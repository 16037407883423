import { useContext, useRef } from "react";
import { WorksLeft, WorksRight } from "../styled";
import { useScroll, useTransform } from "framer-motion";
import { motion } from "framer-motion";
import { ContextProviderWrapper } from "../../../../Components/Context";

interface CardInterFace {
  item: any;
  index: number;
}
const Card = ({ item, index }: CardInterFace) => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1 1"],
  });
  const transForm1 = useTransform(scrollYProgress, [0, 1], [-200, 0]);
  const transForm2 = useTransform(scrollYProgress, [0, 1], [-200, 50]);
  const transForm3 = useTransform(scrollYProgress, [0, 1], [-200, 100]);
  const scale1 = useTransform(scrollYProgress, [0, 1], [1, 0.94]);
  const scale2 = useTransform(scrollYProgress, [0, 1], [1, 0.97]);
  const scale3 = useTransform(scrollYProgress, [0, 1], [1, 1]);

  return (
    <motion.div
      className="item"
      key={index}
      ref={ref}
      style={{
        y:
          item.id === 1 && !isMobile
            ? transForm1
            : item.id === 2 && !isMobile
            ? transForm2
            : transForm3,
        scale:
          item.id === 1 && !isMobile
            ? scale1
            : item.id === 2 && !isMobile
            ? scale2
            : scale3,
        willChange: "transform",
      }}
    >
      <div className="item-inner">
        <WorksLeft>
          <img src={item.img} alt="" />
        </WorksLeft>
        <WorksRight>
          <span className="number">{item.number}</span>
          <span className="title-small">{item.titleSmall}</span>
          <p>{item.text}</p>
        </WorksRight>
      </div>
    </motion.div>
  );
};

export default Card;

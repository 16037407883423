import styled from "styled-components";
import { ButtonContainer } from "../Button/styled";
import bg from '../../Assets/Header/bg_menu.png'

export const HeaderContainer = styled.div`
  padding: 16px 0;
  /* backdrop-filter: blur(50px); */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  @media screen and (max-width: 767px) {
    background: rgba(204, 231, 255, 0.05);
    backdrop-filter: blur(13.092254638671875px);
  }
`;
export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 74.262vw;
  margin: 0 auto;
  padding: 0 20px;
  @media (min-width: 767.98px) and (max-width: 943px) {
    max-width: 96vw;
  }
  @media screen and (max-width: 767px) {
    max-width: 93%;
  }
`;
export const HeaderContentRight = styled.div`
  display: flex;
  justify-content: space-between;
  width: 54vw;
  animation: fade-in 1s linear forwards;
  animation-delay: 1s;
  opacity: 0;
  @media screen and (max-width: 767px) {
    width: unset;
    animation: unset;
    opacity: 1;
    ${ButtonContainer} {
      position: absolute;
      right: 80px;
      top: 50%;
      margin-top: -15px;
    }
  }
  @keyframes fade-in {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`
export const HeaderLogo = styled.div`
  a {
    display: block;
    width: 12.222222vw;
    position: relative;
    top: 6px;
  }
  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    position: relative;
    z-index: 3;
    a {
      top: 0;
      width: 36px;
      height: 36px;
    }
  }
`;
export const ListNavigation = styled.ul`
  display: flex;
  align-items: center;
  gap: 40px;
  transition: transform 0.26s ease;
  position: relative;
  top: 5px;
  a {
    color: #93979D;
    text-decoration: none;
    display: inline-block;
    transition: color .26s ease;
    &:hover {
      color: #fff;
    }
  }
  @media (min-width: 767.98px) and (max-width: 943px) {
    gap: 25px;
  }
  @media screen and (max-width: 767px) {
    display: block;
    position: fixed;
    height: 100vh;
    background: #121315 url(${bg}) center / cover no-repeat;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    transform: translateX(100%);
    padding: 130px 20px;
    bottom: 0;
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, #121315 -31.55%, rgba(18, 19, 21, 0.00) 460.39%);
      mix-blend-mode: darken;
      pointer-events: none;
    }
    &.is-show {
      transform: translateX(0);
    }
    a {
      color: #6E7276;
    }
  }
`;
export const ItemNavigation = styled.li`
  a {
    font-size: 1.388888vw;
  }
  @media screen and (max-width: 767px) {
    text-align: center;
    position: relative;
    z-index: 1;
    a {
      font-size: 40px;
      &.active {
        color: #fff;
      }
    }
  }
`;
//navigation
export const ButtonMenu = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 3;
  span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 100px;
    overflow: hidden;
    background-color: #fff;
    position: absolute;
    transition: all 0.26s ease;
    &:nth-child(1) {
      top: 6px;
      width: 50%;
    }
    &:nth-child(2) {
      top: 15px;
    }
    &:nth-child(3) {
      top: 25px;
    }
  }
  &.is-active {
    span {
      &:nth-child(1) {
        transform: rotateZ(45deg);
        top: 16px;
        width: 100%;
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        top: 16px;
        transform: rotateZ(-45deg);
      }
    }
  }
`;

import React, { useContext, useRef, useState } from "react";
import {
  Chart,
  ChartContent,
  ChartImage,
  InfoItem,
  InfoLeft,
  ItemContent,
  ListContent,
  TokenomicsContainer,
  TokenomicsContentInfo,
  TokenomicsContentTitle,
  TokenomicsWrapper,
} from "./styled";
import chart01 from "../../../Assets/Home/Tokenomics/tokenomics_chart_01.png";
import chart01Sp from "../../../Assets/Home/Tokenomics/tokenomics_chart_01_sp.png";
import { ContextProviderWrapper } from "../../../Components/Context";
import CountUp from "react-countup";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { WorksTitle } from "../Works/styled";

const Tokenomics = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [showNumber, setShowNumber] = useState(false);
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1 1"],
  });
  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest > 0.5) {
      setShowNumber(true);
    } else {
      setShowNumber(false);
    }
  });

  return (
    <TokenomicsContainer ref={ref}>
      <TokenomicsContentTitle>
        <div style={{ overflow: "hidden" }}>
          <WorksTitle data-aos="fade-up">
            Tokenomics
          </WorksTitle>
        </div>
      </TokenomicsContentTitle>
      <TokenomicsContentInfo data-aos="fade-up">
        <InfoLeft>
          {ListInfo.map((item: any, index: number) => (
            <InfoItem key={index}>
              <span className="text-left">{item.textLeft}</span>
              <span className="text-right">{item.textRight}</span>
            </InfoItem>
          ))}
        </InfoLeft>
      </TokenomicsContentInfo>
      <TokenomicsWrapper>
        <Chart>
          <ChartImage>
            <img src={isMobile ? chart01Sp : chart01} alt="Tokenomics" />
            <ChartContent>
              <ListContent>
                {listChart.map((item: any, index: number) => (
                  <ItemContent key={index}>
                    {showNumber && !isMobile && (
                      <CountUp className="text-number" end={item.number} />
                    )}
                    {!isMobile && <span className="text-number">%</span>}
                    {isMobile && (
                      <span className="text-number">{item.number}%</span>
                    )}
                    <span className="text">{item.text}</span>
                  </ItemContent>
                ))}
              </ListContent>
            </ChartContent>
          </ChartImage>
        </Chart>
      </TokenomicsWrapper>
    </TokenomicsContainer>
  );
};

const ListInfo = [
  {
    textLeft: "Token Name: ",
    textRight: "TON Holding",
  },
  {
    textLeft: "Token Type: ",
    textRight: "The Open Network (TON)",
  },
  {
    textLeft: "Total Supply:",
    textRight: "500,000,000 iTON",
  },
  {
    textLeft: "Decimal:",
    textRight: "9",
  },
  {
    textLeft: "Token Ticker:",
    textRight: "iTON",
  },
];

const listChart = [
  {
    number: "17",
    text: "Treasury",
  },
  {
    number: "20",
    text: "Staking",
  },
  {
    number: "12",
    text: "Investors",
  },
  {
    number: "7",
    text: "Airdrop",
  },
  {
    number: "12",
    text: "Team",
  },
  {
    number: "8",
    text: "Incentives",
  },
  {
    number: "5",
    text: "Marketing",
  },
  {
    number: "19",
    text: "Dex listing",
  },
];

export default Tokenomics;

import {
  BlockProvide,
  BlockProvideInner,
  BlockProvideList,
  BlockProvideText,
  ItemProvide,
  OrbitContainer,
  WorksTitle,
} from "../styled";
import Provide01 from "../../../../Assets/Home/Works/provide_icn_01.png";
import Provide02 from "../../../../Assets/Home/Works/provide_icn_02.png";
import Provide03 from "../../../../Assets/Home/Works/provide_icn_03.png";
const Provide = () => {
  return (
    <BlockProvide>
      <BlockProvideInner>
        <OrbitContainer data-aos="fade-right">
          <div className="orbit-line 1">
            <span></span>
          </div>
          <div className="orbit-line 2">
            <span></span>
          </div>
          <div className="orbit-line 3">
            <span></span>
          </div>
        </OrbitContainer>
        <div style={{ overflow: "hidden" }}>
          <WorksTitle data-aos="fade-up">What do we provide</WorksTitle>
        </div>
        <BlockProvideText data-aos="fade-right">
          As a Funding and a Liquid Staking Protocol, TON Holding brings you
          several functions
        </BlockProvideText>
      </BlockProvideInner>
      <BlockProvideList>
        {ListProvide.map((item: any, index: number) => (
          <ItemProvide
            key={index}
            data-aos={item.animation}
            data-aos-delay={item.delay}
          >
            <img src={item.image} alt="" />
            <h3>
              <span className="title-small">{item.titleSmall}</span>
              <span className="title-big">{item.titleBig}</span>
            </h3>
            <p>{item.text}</p>
          </ItemProvide>
        ))}
      </BlockProvideList>
    </BlockProvide>
  );
};

const ListProvide = [
  {
    image: Provide01,
    titleSmall: "TON Holding",
    titleBig: "Funding",
    text: "We offer efficient asset management solutions, allowing investors to engage in early-stage token investment or provide liquidity to earn rewards corresponding to their stake amount.",
    animation: "fade-up-right",
    delay: "0",
  },
  {
    image: Provide02,
    titleSmall: "TON Holding",
    titleBig: "Nominator",
    text: "It allows users to stake TON and become a nominator, earning rewards from Validators (up to 60%) with daily profit distribution to Stakers.",
    animation: "fade-up",
    delay: "500",
  },
  {
    image: Provide03,
    titleSmall: "Add Liquidity",
    titleBig: "for iTON/TON",
    text: "TON Holding establishes token swap pools for transactions, providing liquidity for the iTON/TON pair. Stakers earn passive income through liquidity provision.",
    animation: "fade-up-left",
    delay: "1000",
  },
];

export default Provide;

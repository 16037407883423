import styled from "styled-components";
import line1 from "../../../Assets/Home/Tokenomics/line_01.png";
import line2 from "../../../Assets/Home/Tokenomics/line_02.png";
import line3 from "../../../Assets/Home/Tokenomics/line_03.png";
import line4 from "../../../Assets/Home/Tokenomics/line_04.png";
import line5 from "../../../Assets/Home/Tokenomics/line_05.png";
import line6 from "../../../Assets/Home/Tokenomics/line_06.png";
import line7 from "../../../Assets/Home/Tokenomics/line_07.png";
import line8 from "../../../Assets/Home/Tokenomics/line_08.png";

export const TokenomicsContainer = styled.div``;
export const TokenomicsWrapper = styled.div`
  max-width: 70.262vw;
  margin: 0 auto;
  padding: 0 20px;
  @media screen and (max-width: 767px) {
    max-width: unset;
    padding-left: 15px;
    padding-right: 15px;
  }
`;
export const TokenomicsContentTitle = styled.div`
  text-align: center;
`;
export const TokenomicsContentInfo = styled.div`
  border-top: 1px solid #363a4140;
  border-bottom: 1px solid #363a4140;
  background: linear-gradient(
    90deg,
    rgba(204, 231, 255, 0) 0%,
    rgba(204, 231, 255, 0.05) 54.5%,
    rgba(204, 231, 255, 0) 100%
  );
  padding: 27px 0 16px;
`;
export const InfoLeft = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 71.8vw;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    max-width: unset;
    display: block;
    padding: 0 15px;
  }
`;
export const InfoItem = styled.li`
  width: calc(100% / 3);
  margin-bottom: 7px;
  .text-left {
    color: var(--Light-Gray-Base, #93979d);
    font-size: 1.388888vw;
    font-weight: 400;
    max-width: 7.7vw;
    width: 100%;
    display: inline-block;
  }
  .text-right {
    color: var(--Light-Gray-70, #dce3eb);
    font-size: 1.388888vw;
    font-weight: 400;
    margin-left: 10px;
  }
  @media screen and (max-width: 767px) {
    width: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text-left,
    .text-right {
      font-size: 20px;
    }
    .text-left {
      max-width: unset;
      width: unset;
    }
    .text-right {
      margin-left: 0;
    }
  }
`;
export const Chart = styled.div`
  text-align: center;
  position: relative;
  padding: 50px 0;
`;
export const ChartImage = styled.div`
  width: 54.0278vw;
  margin: 0 auto;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40.625vw;
    height: 40.625vw;
    flex-shrink: 0;
    border-radius: 585.565px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 87, 255, 0.3) 0%,
      rgba(0, 87, 255, 0) 100%
    );
    filter: blur(150px);
  }
  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: unset;
    &:before {
      width: 100%;
      height: 100%;
    }
  }
`;
export const ChartContent = styled.div``;
export const ListContent = styled.ul``;
export const ItemContent = styled.li`
  display: flex;
  align-items: center;
  position: absolute;
  &:before {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    background: center / contain no-repeat;
  }
  &:nth-child(1) {
    top: 7.1vw;
    left: calc(50% - 28vw);
    &:before {
      background-image: url(${line1});
      width: 8vw;
      height: 2.4vw;
      left: 108%;
      top: 74%;
    }
  }
  &:nth-child(2) {
    top: 7.1vw;
    right: calc(50% - 28.1vw);
    &:before {
      background-image: url(${line2});
      width: 8vw;
      height: 2.4vw;
      right: 108%;
      top: 72%;
    }
  }
  &:nth-child(3) {
    top: 19.7vw;
    left: calc(50% - 29.7vw);
    &:before {
      background-image: url(${line3});
      width: 3.9vw;
      height: 0.139vw;
      left: 108%;
    }
  }
  &:nth-child(4) {
    top: 18vw;
    right: calc(50% - 28.6vw);
    &:before {
      background-image: url(${line4});
      width: 4.7vw;
      height: 0.139vw;
      right: 108%;
    }
  }
  &:nth-child(5) {
    top: 28.4vw;
    left: calc(50% - 26vw);
    &:before {
      background-image: url(${line5});
      width: 5.6vw;
      height: 0.139vw;
      left: 108%;
    }
  }
  &:nth-child(6) {
    top: 26vw;
    right: calc(50% - 30.9vw);
    &:before {
      background-image: url(${line6});
      width: 5.5vw;
      height: 0.139vw;
      right: 108%;
    }
  }
  &:nth-child(7) {
    top: 34.8vw;
    left: calc(50% - 26.9vw);
    &:before {
      background-image: url(${line7});
      width: 10.6vw;
      height: 0.139vw;
      left: 108%;
    }
  }
  &:nth-child(8) {
    top: 36.2vw;
    right: calc(50% - 27vw);
    &:before {
      background-image: url(${line8});
      width: 8vw;
      height: 2.1vw;
      right: 108%;
      top: 22%;
    }
  }
  &:nth-child(2n + 2) {
    .text {
      order: 1;
      margin-right: 10px;
      margin-left: 0;
    }
    .text-number {
      order: 2;
    }
  }
  .text-number {
    color: var(--Light-Gray-Base, #dce3eb);
    font-size: 2.430555vw;
    font-weight: 400;
  }
  .text {
    color: var(--Light-Gray-70, #93979d);
    text-align: right;
    font-size: 1.527777vw;
    font-weight: 400;
    margin-left: 10px;
  }
  @media screen and (max-width: 767px) {
    display: block;
    &:nth-child(1) {
      top: 15%;
      left: 26%;
    }
    &:nth-child(2) {
      top: 17%;
      right: 20%;
    }
    &:nth-child(3) {
      top: 40%;
      left: 8%;
    }
    &:nth-child(4) {
      top: 41%;
      right: 5%;
    }
    &:nth-child(5) {
      top: 66%;
      left: 16%;
    }
    &:nth-child(6) {
      top: 57%;
      right: 4%;
    }
    &:nth-child(7) {
      top: 79%;
      left: 27%;
    }
    &:nth-child(8) {
      top: 78%;
      right: 26%;
    }
    span {
      display: block;
    }
    &:before {
      content: none;
    }
    .text-number {
      font-size: 22px;
      line-height: 1;
    }
    .text {
      color: #000;
      font-size: 16px;
      line-height: 1;
      margin-left: 0;
    }
  }
`;

import styled from "styled-components";
import bg from "../../../Assets/Home/Roadmap/roadmap_bg.png";

export const RoadmapContainer = styled.div`
  padding-top: 9vw;
  padding-bottom: 9vw;
  background: linear-gradient(180deg, #000 0%, #272727 100%);
  position: relative;
  min-height: 1200px;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;
export const RoadmapWrapper = styled.div`
  position: relative;
  .content-title {
    max-width: 70.262vw;
    margin: 0 auto;
    padding: 0 20px 3vw;
  }
  @media screen and (max-width: 767px) {
    .content-title {
      max-width: unset;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;
export const RoadmapList = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 60px;
  position: relative;
  max-width: 71.5vw;
  margin: 0 auto;
  padding: 0 20px 5vw;
  &:before {
    position: absolute;
    content: "";
    top: 1.5vw;
    left: 50%;
    background: url(${bg}) center / cover no-repeat;
    aspect-ratio: 1440 / 6;
    width: 145%;
    transform: translate(-50%, -44%);
    pointer-events: none;
  }
  &:after {
    position: absolute;
    content: "";
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 537px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 87, 255, 0.15) 0%,
      rgba(0, 87, 255, 0) 100%
    );
    mix-blend-mode: plus-lighter;
    filter: blur(150px);
    width: 537px;
    height: 537px;
    pointer-events: none;
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
    max-width: unset;
    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
  }
`;
export const RoadmapItem = styled.li`
  position: relative;
  padding-top: 5.9vw;
  &:after {
    position: absolute;
    top: 0vw;
    right: 2vw;
    font-size: 8vw;
    font-weight: 500;
    background: linear-gradient(
      180deg,
      #444750 -22.67%,
      rgba(68, 71, 80, 0) 70.93%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    aspect-ratio: 1;
    width: 23.34%;
  }
  h3 {
    color: var(--Gray-50, #9ba1b0);
    font-size: 1.666666vw;
    font-weight: 500;
  }
  .item-text {
    color: var(--Gray-60, #717685);
    font-size: 0.97222vw;
    font-weight: 400;
    position: relative;
    padding-left: 10px;
    &:before {
      position: absolute;
      content: "";
      top: 11px;
      left: 0;
      width: 3px;
      height: 3px;
      background-color: #717685;
      margin-top: -3px;
    }
  }
  &:nth-child(1) {
    width: 20.6%;
    &:after {
      content: "1";
      right: 5vw;
      background: linear-gradient(
        180deg,
        #444750 -22.67%,
        rgba(68, 71, 80, 0) 70.93%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &:nth-child(2) {
    width: 20.8%;
    &:after {
      content: "2";
    }
  }
  &:nth-child(3) {
    width: 19.2%;
    &:after {
      content: "3";
      background: linear-gradient(
        180deg,
        rgba(45, 47, 53, 0.25) -22.67%,
        rgba(45, 47, 53, 0) 100.3%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &:nth-child(4) {
    width: 18%;
    &:after {
      content: "4";
      background: linear-gradient(
        180deg,
        rgba(45, 47, 53, 0.25) -22.67%,
        rgba(45, 47, 53, 0) 100.3%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.is-active {
    h3 {
      color: var(--Blue-Base, #0085ff);
    }
    .item-text {
      color: #fff;
    }
  }
  @media screen and (max-width: 767px) {
    width: 41% !important;
    aspect-ratio: unset !important;
    position: static !important;
    &:after {
      content: none !important;
    }
    &:nth-child(1) {
      img {
        width: 23.34%;
      }
    }
    img {
      aspect-ratio: unset !important;
      position: static !important;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 20px;
    }
    .item-text {
      font-size: 14px;
    }
  }
`;

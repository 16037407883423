import { Toaster } from "react-hot-toast";
import "./App.css";
import HomeLayout from "./Layout/Home";
import { AppContainer } from "./styled";
import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/Home";
import ReactLenis from "@studio-freight/react-lenis";

const App = () => {
  return (
    // <ReactLenis
    //   root
    //   options={{
    //     duration: 3,
    //     smoothWheel: true,
    //   }}
    // >
      <AppContainer>
        {/* <Toaster position="top-right" reverseOrder={false} /> */}
        <Routes>
          <Route path="/" element={<HomeLayout />}>
            <Route index path="" element={<HomePage />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AppContainer>
    // </ReactLenis>
  );
};

export default App;

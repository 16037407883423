import React from "react";
import {
  ContentLeft,
  ContentRight,
  Diamon01,
  Diamon02,
  KeyvisualContainer,
  KeyvisualContent,
  KeyvisualContentTitle,
  KeyvisualTitle,
  KeyvisualVideoBg,
  KeyvisualWrapper,
  KeyvisualWrapperInnerTitle,
  TextBalance,
} from "./styled";
import ButtonCommon from "../../../Components/Button";
import icn01 from "../../../Assets/Home/Keyvisual/icn_01.svg";
import img01 from "../../../Assets/Home/Keyvisual/keyvisual_img_01.png";
import diamon01 from "../../../Assets/Home/Keyvisual/diamon_01.png";
import diamon02 from "../../../Assets/Home/Keyvisual/diamon_02.png";
import 'animate.css';

const Keyvisual = () => {
  return (
    <KeyvisualContainer className="keyvisual">
      <KeyvisualVideoBg>
        <video autoPlay muted loop playsInline>
          <source src="./video/keyvisual_video.mp4" type="video/mp4" />
        </video>
      </KeyvisualVideoBg>
      <KeyvisualWrapper>
        <KeyvisualWrapperInnerTitle>
          <KeyvisualContentTitle>
            <KeyvisualTitle className="animate__bounceIn animate__delay-1s animate__animated animate__slow">
              <span className="title-inner">Master Your Finances</span>
              <span className="title-inner">Grow Your </span>
            </KeyvisualTitle>
            <TextBalance className="animate__bounceIn animate__delay-1s animate__animated animate__slow">
              <span>Balance</span>
            </TextBalance>
          </KeyvisualContentTitle>
        </KeyvisualWrapperInnerTitle>
        <KeyvisualContent>
          <ContentLeft data-aos="fade-right">
            <p>
              Stake Smart, Reap Rewards. Decentralized, Secure and Easy
              Investment with TON Holding
            </p>
            <ButtonCommon text="Stake Now" image={icn01} />
          </ContentLeft>
          <ContentRight data-aos="fade-left">
            <img src={img01} alt="" />
          </ContentRight>
        </KeyvisualContent>
      </KeyvisualWrapper>
      <Diamon01>
        <img src={diamon01} alt="" />
        {/* <video autoPlay muted loop playsInline>
          <source src="./video/keyvisual_02.mp4" type="video/mp4" />
        </video> */}
      </Diamon01>
      <Diamon02>
        {/* <video autoPlay muted loop playsInline>
          <source src="./video/keyvisual_02.mp4" type="video/mp4" />
        </video> */}
        <img src={diamon02} alt="" />
      </Diamon02>
    </KeyvisualContainer>
  );
};

export default Keyvisual;

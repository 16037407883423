import React from "react";
import { PartnerContainer, PartnerList, PartnerWrapper } from "./styled";
import icnImg01 from "../../../Assets/Home/Partner/partner_img_01.png";
import icnImg02 from "../../../Assets/Home/Partner/partner_img_02.png";
import icnImg03 from "../../../Assets/Home/Partner/partner_img_03.png";
import icnImg04 from "../../../Assets/Home/Partner/partner_img_04.png";
import icnImg05 from "../../../Assets/Home/Partner/partner_img_05.png";
import { WorksTitle } from "../Works/styled";

const Partner = () => {
  return (
    <PartnerContainer>
      <PartnerWrapper>
        <div className="content-title">
          <WorksTitle data-aos="fade-right">Backer and Partner</WorksTitle>
        </div>
        <PartnerList>
          {listPartner.map((item: any, index: number) => (
            <li key={index} data-aos={item.animation}>
              <img src={item.img} alt="" />
            </li>
          ))}
        </PartnerList>
      </PartnerWrapper>
    </PartnerContainer>
  );
};

const listPartner = [
  {
    animation: "fade-right",
    img: icnImg01,
  },
  {
    animation: "fade-up",
    img: icnImg02,
  },
  {
    animation: "fade-up-left",
    img: icnImg04,
  },
  {
    animation: "fade-left",
    img: icnImg03,
  },
  {
    animation: "fade-up-left",
    img: icnImg05,
  },
];

export default Partner;

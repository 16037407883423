import React, { useContext, useEffect } from "react";
import {
  RoadmapContainer,
  RoadmapItem,
  RoadmapList,
  RoadmapWrapper,
} from "./styled";
import { WorksTitle } from "../Works/styled";
import icn01 from "../../../Assets/Home/Roadmap/roadmap_icn_01.svg";
import icn02 from "../../../Assets/Home/Roadmap/roadmap_icn_02.svg";
import icn03 from "../../../Assets/Home/Roadmap/roadmap_icn_03.svg";
import { ContextProviderWrapper } from "../../../Components/Context";
import Tokenomics from "../Tokenomics";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Partner from "../Partner";

const Roadmap = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  //
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration
    });
  }, []);
  return (
    <RoadmapContainer id="map">
      <RoadmapWrapper>
        <div className="content-title">
          <div style={{ overflow: "hidden" }}>
            <WorksTitle data-aos="fade-right">
              Road Map
            </WorksTitle>
          </div>
        </div>
        <RoadmapList>
          {Data.map((items: any, index: number) => (
            <RoadmapItem
              key={index}
              className={items.active ? "is-active" : ""}
              data-aos={items.animation}
              data-aos-delay={items.delay}
            >
              {!isMobile ? (
                <img src={items.active ? icn02 : icn01} alt="" />
              ) : (
                <img src={items.active ? icn03 : icn01} alt="" />
              )}
              <h3>{items.title}</h3>
              {items.listText.map((item: any) => (
                <p className="item-text">{item}</p>
              ))}
            </RoadmapItem>
          ))}
        </RoadmapList>
        {/* Tokennomics */}
        <Tokenomics />
        <Partner />
      </RoadmapWrapper>
    </RoadmapContainer>
  );
};

const Data = [
  {
    title: "Phase 1",
    listText: [
      "Researching Market",
      "Competitor Analysis",
      "Setting Up Website",
      "Building Product",
    ],
    active: true,
    animation: "zoom-in",
    delay: "0"
  },
  {
    title: "Phase 2",
    listText: [
      " Developing & Testing Staking",
      "Improving UI",
      "Performing Security Audits",
      "Completing GTM Strategy",
    ],
    animation: "zoom-in",
    delay: "300"
  },
  {
    title: "Phase 3",
    listText: [
      "Establishing The Staking on TON Blockchain",
      "Running The TON Holding",
      "Funding",
      "ICO",
      "Collaborating Partners",
    ],
    animation: "zoom-in",
    delay: "600"
  },
  {
    title: "Phase 4",
    listText: [
      "Adding Liquidity to DEXs On TON Blockchain",
      "Improving Product",
      "Releasing Cross-chain Bridge",
      "Releasing NFT Game"
    ],
    animation: "zoom-in",
    delay: "900"
  },
];

export default Roadmap;

import React from 'react'
import { ButtonContainer, ButtonWrapper } from './styled'
import { Link } from 'react-router-dom'
import 'aos/dist/aos.css';

interface ButtonInterface {
  image: string,
  text: string,
  href?: any,
  animation?: any,
  newTabs?: any
}

const ButtonCommon = ({image, text, href,animation, newTabs}: ButtonInterface) => {
  return (
    <ButtonContainer data-aos={animation}>
      <ButtonWrapper>
        <Link to={href} className='hvr-icon-bounce' target={newTabs && "_blank"}>
          <span>{text}</span>
          <img className='hvr-icon' src={image} alt="" />
        </Link>
      </ButtonWrapper>
    </ButtonContainer>
  )
}

export default ButtonCommon
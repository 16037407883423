import styled from "styled-components";

export const PartnerContainer = styled.div``;
export const PartnerWrapper = styled.div`
  .content-title {
    text-align: center;
  }
  @media screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
export const PartnerList = styled.ul`
  max-width: 71.6vw;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2vw;
  li {
    width: 22.223vw;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    max-width: unset;
    gap: 16px;
    justify-content: space-between;
    li {
      width: calc(100% / 2 - 8px);
    }
  }
`;

import styled from "styled-components";
import bg from "../../../Assets/Choose/choose_img_03.png";
import bgSecurity from "../../../Assets/Choose/bg.png";
import decor01 from "../../../Assets/Choose/choose_decor_01.png";
import decor02 from "../../../Assets/Choose/choose_decor_02.png";

export const ChooseContainer = styled.div`
  padding-top: 80px;
  background: linear-gradient(180deg, #020202 0%, #1b1d20 58.51%, #121315 100%);
  @media screen and (max-width: 767px) {
    overflow: hidden;
  }
`;
export const ChooseWrapper = styled.div`
  max-width: 73.178vw;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  @media screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
    max-width: unset;
    width: unset;
  }
`;
export const ChooseBlockFirst = styled.div``;
export const ChooseTitle = styled.h2`
  max-width: 65.5vw;
  width: 100%;
  margin: 0 auto 10vw;
  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    max-width: unset;
    margin-bottom: 60px;
    padding-left: 15px;
    padding-right: 15px;
    width: unset;
  }
`;
export const ChooseContentFirst = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
export const ChooseList = styled.ul`
  /* &.list-01 {
    li {
      &:nth-child(1) {
        .content-image {
          top: 20px;
        }
        img {
          width: 15.556vw;
        }
      }
    }
  }
  &.list-02 {
    li {
      &:nth-child(2) {
        img {
          margin: 0 auto;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    &.list-01 {
      li {
        &:nth-child(1) {
          .content-image {
            width: 63%;
          }
          img {
            width: 100%;
          }
        }
        &:nth-child(2) {
          .content-image {
            width: 63%;
          }
          img {
            width: 100%;
          }
        }
      }
    }
    &.list-02 {
      li {
        &:nth-child(1) {
          .content-image {
            width: 70%;
          }
          img {
            width: 100%;
          }
        }
        &:nth-child(2) {
          .content-image {
            width: 58%;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  } */
`;
export const ChooseItem = styled.li`
  border-radius: 16px;
  background: linear-gradient(180deg, #1b1c20 25.84%, #2d2f35 170.64%);
  padding: 0 1.1vw;
  width: 19.132vw;
  min-height: 18.541vw;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .content-image {
    text-align: center;
    width: 15.556vw;
    height: 12.014vw;
    margin: 0 auto;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  h3 {
    color: var(--Light-Gray-10, #f8f9fb);
    font-size: 1.388888vw;
    font-weight: 500;
  }
  p {
    color: var(--Gray-40, #afb4c0);
    font-size: 0.97223vw;
    font-weight: 400;
  }
  @media screen and (max-width: 767px) {
    width: unset;
    min-height: unset;
    padding: 0 16px 17px;
    overflow: hidden;
    background: #2d2f35;
    .content-image {
      height: 173px;
      width: 224px;
    }
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
`;
export const ChooseContentBig = styled.div`
  background: url(${bg}) center / cover no-repeat;
  min-height: 38.264vw;
  width: 26.728vw;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  video {
    mix-blend-mode: plus-lighter;
    margin-top: auto;
    width: 209%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10vw;
  }
  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 779px;
    background: radial-gradient(
      47.43% 47.43% at 50% 50%,
      #0057ff 0%,
      rgba(0, 133, 255, 0) 100%
    );
    filter: blur(10.096933364868164px);
    width: 54.1vw;
    height: 54.1vw;
    pointer-events: none;
  }
  h3 {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .title-small {
    color: #fff;
    text-align: center;
    font-size: 44px;
    font-weight: 400;
  }
  .title-big {
    color: #fff;
    font-size: 56px;
    font-weight: 500;
    display: block;
    margin-top: -23px;
  }
  @media screen and (max-width: 767px) {
    width: unset;
    margin: 16px 0;
    min-height: 450px;
    &:before {
      width: 200%;
      height: 200%;
    }
    video {
      width: 149%;
      bottom: -110px;
      transform: translateX(-50%);
      left: 50%;
    }
    .title-small {
      font-size: 34px;
    }
    .title-big {
      font-size: 38px;
      margin-top: -10px;
    }
  }
`;

//block second
export const ChooseBlockSecond = styled.div`
  padding-top: 80px;
  margin-top: 80px;
  position: relative;
  /* min-height: 688px; */
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, #1c1e20 0%, rgba(0, 0, 0, 0) 100%),
      url(${bgSecurity}) lightgray -35.486px -115.06px / 104.722% 123.618%
        no-repeat;
    mix-blend-mode: soft-light;
    pointer-events: none;
  }
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(-180deg, #1c1e20 0%, rgba(0, 0, 0, 0) 100%);
  }
  .content-title {
    text-align: center;
    position: relative;
    z-index: 1;
    margin-bottom: 2vw;
  }
  @media screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
    .content-title {
      margin-bottom: 10px;
    }
  }
`;
export const ChooseBlockText = styled.p`
  color: var(--Light-Gray-Base, #dce3eb);
  text-align: center;
  font-size: 1.11111vw;
  font-weight: 300;
  max-width: 20vw;
  margin: 0 auto;
  position: relative;
  margin-bottom: 4vw;
  z-index: 1;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    max-width: unset;
    margin-bottom: 50px;
  }
`;
export const ChooseBlockList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  max-width: 73.262vw;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 767px) {
    max-width: unset;
    display: block;
    padding: 0;
  }
`;
export const ChooseBlockItem = styled.li`
  max-width: 19.1vw;
  width: 100%;
  border-radius: 13.965px;
  border: 1px solid #57555547;
  background: rgba(204, 231, 255, 0.05);
  backdrop-filter: blur(13.092254638671875px);
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  min-height: 16.8vw;
  .content-inner {
    margin-top: auto;
  }
  .icon {
    position: relative;
    display: inline-block;
    /* width: 3.473vw;
    height: 3.473vw; */
    width: 50px;
    height: 50px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  h3 {
    color: var(--Light-Gray-10, #f8f9fb);
    font-size: 1.944444vw;
    font-weight: 500;
    line-height: 1;
  }
  p {
    color: var(--Light-Gray-Base, #dce3eb);
    font-size: 1.11111vw;
    font-weight: 400;
  }
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 13.965px;
    background: rgba(204, 231, 255, 0.2);
    box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(13.092254638671875px);
    opacity: 0;
    transition: opacity 0.26s ease;
    pointer-events: none;
  }
  &:after {
    position: absolute;
    content: "";
    background: center / contain no-repeat;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
  &:nth-child(1) {
    &:after {
      background: url(${decor01}) top right / 81px auto no-repeat;
      width: 5.556vw;
      height: 5.556vw;
      top: 0;
      right: 0;
    }
  }
  &:nth-child(3) {
    &:after {
      background: url(${decor02}) bottom right / 81px auto no-repeat;
      width: 5.764vw;
      height: 5.139vw;
      right: 0;
      bottom: 0;
    }
  }
  @media screen and (max-width: 767px) {
    max-width: unset;
    min-height: 182px;
    width: unset;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &:nth-child(1) {
      &:after {
        width: 80px;
        height: 81px;
      }
    }
    &:nth-child(3) {
      &:after {
        width: 83px;
        height: 74px;
      }
    }
    h3 {
      font-size: 28px;
    }
    p {
      font-size: 16px;
    }
  }
`;

import styled from "styled-components";

export const KeyvisualContainer = styled.div`
  position: relative;
  z-index: 1;
  min-height: 76.5vh;
  overflow: hidden;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(158deg, #2d2f35 10.48%, rgba(0, 0, 0, 0) 60.85%),
      linear-gradient(
        180deg,
        rgba(18, 19, 21, 0) 26.61%,
        rgba(12, 12, 13, 0.49) 54.22%,
        rgba(8, 8, 9, 0.77) 74.31%,
        rgba(6, 7, 7, 0.95) 85.32%,
        #050505 100%
      ),
      linear-gradient(263deg, #050606 13.06%, rgba(27, 28, 32, 0) 67.91%);
    z-index: 1;
    pointer-events: none;
  }
  @media screen and (max-width: 767px) {
    min-height: unset;
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
`;
export const KeyvisualVideoBg = styled.div`
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;
export const KeyvisualWrapper = styled.div`
  position: relative;
  z-index: 1;
  padding-top: 9.7vw;
  @media screen and (max-width: 767px) {
    padding-top: 140px;
  }
`;
export const KeyvisualWrapperInnerTitle = styled.div`
  text-align: center;
`;
export const KeyvisualContentTitle = styled.div`
  position: relative;
  display: inline-block;
`;
export const KeyvisualTitle = styled.h1`
  text-align: center;
  font-size: 7.9861vw;
  font-weight: 600;
  background: linear-gradient(90deg, #fff 38.73%, #5a5f6b 129.1%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 auto;
  .title-inner {
    display: block;
    &:nth-child(2) {
      margin-top: -50px;
      text-align: left;
      padding-left: 50px;
    }
  }
  @media (min-width: 767.98px) and (max-width: 943px) {
    font-size: 70px;
    .title-inner {
      &:nth-child(2) {
        margin-top: -30px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 34px;
    .title-inner {
      &:nth-child(2) {
        margin-top: -15px;
        padding-left: 19px;
      }
    }
  }
`;
export const TextBalance = styled.span`
  line-height: 0.7;
  padding: 2.4vw 0.8vw 0;
  border-radius: 14.543px;
  border: 0.909px solid #495c7e54;
  background: rgba(204, 231, 255, 0.1);
  display: inline-block;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 1;
  color: #fff;
  font-size: 7.9861vw;
  font-weight: 600;
  position: absolute;
  right: 4vw;
  bottom: 4vw;
  @media (min-width: 767.98px) and (max-width: 943px) {
    font-size: 70px;
  }
  @media screen and (max-width: 767px) {
    font-size: 34px;
    right: -2px;
    bottom: 14px;
    padding: 15px 10px 0px;
  }
`;
export const Diamon01 = styled.div`
  width: 5.973vw;
  height: 5.973vw;
  left: calc(50% - 37vw);
  top: 6vw;
  position: absolute;
  z-index: 1;
  animation: animationDiamon 5s linear infinite;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @keyframes animationDiamon {
    0% {
      transform: translateY(0) scale(1);
    }
    50% {
      transform: translateY(20px) scale(0.9);
    }
    100% {
      transform: translateY(0) scale(1);
    }
  }
  @media screen and (max-width: 767px) {
    width: 56px;
    height: 57px;
    left: 17px;
    top: 98px;
  }
`;
export const Diamon02 = styled.div`
  width: 7.8475vw;
  height: 7.8475vw;
  right: calc(50% - 42vw);
  top: 23.3vw;
  position: absolute;
  z-index: 1;
  animation: animationDiamon2 5s linear infinite;
  animation-delay: 1s;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @keyframes animationDiamon2 {
    0% {
      transform: translateY(0) translateX(0) scale(1);
    }
    50% {
      transform: translateY(20px) translateX(10px) scale(0.9);
    }
    100% {
      transform: translateY(0) translateX(0) scale(1);
    }
  }
  @media screen and (max-width: 767px) {
    width: 73px;
    height: 74px;
    right: 20px;
    top: 93px;
  }
`;
export const KeyvisualContent = styled.div`
  max-width: 70.262vw;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    padding: 0 15px;
    max-width: unset;
  }
`;
export const ContentLeft = styled.div`
  width: 19vw;
  p {
    color: var(--Light-Gray-Base, #dce3eb);
    font-size: 1.11111vw;
    font-weight: 300;
    margin-bottom: 1vw;
  }
  @media screen and (max-width: 767px) {
    width: 274px;
    p {
      font-size: 16px;
    }
  }
`;
export const ContentRight = styled.div`
  width: 22.5vw;
  position: relative;
  top: -6.7vw;
  right: 7vw;
  &:before {
    border-radius: 368px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 87, 255, 0.5) 0%,
      rgba(0, 87, 255, 0) 100%
    );
    filter: blur(93.437219px);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    right: 0;
    top: -40px;
    width: 214px;
    &:before {
      filter: blur(33.437219px);
    }
  }
`;

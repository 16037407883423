import styled from "styled-components";

export const ButtonContainer = styled.div`
  a {
    border-radius: 29.455px;
    background: radial-gradient(50% 50% at 50% 50%, #55aeff 0%, #0085ff 100%);
    box-shadow: 0px 0px 1px 4px rgba(255, 255, 255, 0.1),
      0px -4px 2px 0px rgba(0, 0, 0, 0.25) inset,
      0px 2px 1px 0px rgba(255, 255, 255, 0.25) inset;
    padding: 3px 24px 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;
    font-size: 1.11111vw;
  }
  img {
    margin-left: 10px;
    position: relative;
    top: 1px;
    width: 1.181vw;
  }
  span {
    padding-top: 6px;
  }
  @media screen and (max-width: 767px) {
    a {
      padding-left: 12px;
      padding-right: 12px;
      font-size: 14px;
    }
    img {
      margin-left: 6px;
      width: 17px;
    }
  }
`;
export const ButtonWrapper = styled.div``;

import React, { useContext } from "react";
import {
  ChooseBlockFirst,
  ChooseBlockItem,
  ChooseBlockList,
  ChooseBlockSecond,
  ChooseBlockText,
  ChooseContainer,
  ChooseContentBig,
  ChooseContentFirst,
  ChooseItem,
  ChooseList,
  ChooseTitle,
  ChooseWrapper,
} from "./styled";
import title from "../../../Assets/Choose/Choose_title.png";
import titleSp from "../../../Assets/Choose/Choose_title_sp.png";
import chooseImg1 from "../../../Assets/Choose/choose_img_01.png";
import chooseImg2 from "../../../Assets/Choose/choose_img_02.png";
import chooseImg4 from "../../../Assets/Choose/choose_img_05.png";
import chooseImg5 from "../../../Assets/Choose/choose_img_06.png";
import Security from "./Security";
import { ContextProviderWrapper } from "../../../Components/Context";

const Choose = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;

  return (
    <ChooseContainer id="why">
      <ChooseWrapper>
        <ChooseBlockFirst>
          <ChooseTitle data-aos="fade-up">
            <img
              src={isMobile ? titleSp : title}
              alt="The decentralized solution for liquid staking on TON"
            />
          </ChooseTitle>
          <ChooseContentFirst>
            <ChooseList className="list-01">
              {listDataFirst.map((item: any, index: any) => (
                <ChooseItem key={index} data-aos={item.animation} data-aos-delay={item.delay}>
                  <div className="content-image">
                    <img src={item.img} alt="" />
                  </div>
                  <h3>{item.title}</h3>
                  <p>{item.text}</p>
                </ChooseItem>
              ))}
            </ChooseList>
            <ChooseContentBig>
              <h3>
                <span className="title-small">Why Choose</span>
                <span className="title-big">TON Holding</span>
              </h3>
              <video autoPlay muted loop playsInline>
                <source src="./video/choose_video.mp4" type="video/mp4" />
              </video>
            </ChooseContentBig>
            <ChooseList className="list-02">
              {listDataSecond.map((item: any, index: any) => (
                <ChooseItem key={index} data-aos={item.animation} data-aos-delay={item.delay}>
                  <div className="content-image">
                    <img src={item.img} alt="" />
                  </div>
                  <h3>{item.title}</h3>
                  <p>{item.text}</p>
                </ChooseItem>
              ))}
            </ChooseList>
          </ChooseContentFirst>
        </ChooseBlockFirst>
      </ChooseWrapper>
      <Security />
    </ChooseContainer>
  );
};

const listDataFirst = [
  {
    img: chooseImg1,
    title: "Easy to Stake",
    text: "Begin your staking with just 1 TON and start earning with ease",
    animation: "fade-right",
    delay: '0'
  },
  {
    img: chooseImg2,
    title: "Amplify your balance",
    text: "By staking, your balance can grow directly in your wallet",
    animation: "fade-right",
    delay: '500'
  },
];
const listDataSecond = [
  {
    img: chooseImg4,
    title: "Supported by experts",
    text: "Our experiences experts team supports you to invest in the right places",
    animation: "fade-left",
    delay: '0'
  },
  {
    img: chooseImg5,
    title: "Optimize rewards",
    text: "Earn your passive income through DeFi activities",
    animation: "fade-left",
    delay: '500'
  },
];

export default Choose;

import {
  ChooseBlockItem,
  ChooseBlockList,
  ChooseBlockSecond,
  ChooseBlockText,
} from "../styled";
import { WorksTitle } from "../../Works/styled";
import chooseIcn01 from "../../../../Assets/Choose/choose_icn_01.svg";
import chooseIcn02 from "../../../../Assets/Choose/choose_icn_02.svg";
import chooseIcn03 from "../../../../Assets/Choose/choose_icn_03.svg";

const Security = () => {
  return (
    <ChooseBlockSecond id="audits">
      <div className="content-title">
        <div style={{ overflow: "hidden" }}>
          <WorksTitle data-aos="fade-up">TON Holding Securities</WorksTitle>
        </div>
      </div>
      {/* <ChooseBlockText data-aos="fade-up">
        As a Funding and a Liquid Staking Protocol, TON Holding brings you
        several functions
      </ChooseBlockText> */}
      <ChooseBlockList>
        {listSecurity.map((item: any, index: number) => (
          <ChooseBlockItem
            key={index}
            data-aos={item.animation}
            data-aos-delay={item.delay}
          >
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
              >
                <path
                  d="M50.0035 25.4669C50.0035 39.0181 39.018 50.0036 25.4668 50.0036C11.9156 50.0036 0.930115 39.0181 0.930115 25.4669C0.930115 11.9156 11.9156 0.930176 25.4668 0.930176C39.018 0.930176 50.0035 11.9156 50.0035 25.4669Z"
                  fill="url(#paint0_linear_154_12)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_154_12"
                    x1="25.4668"
                    y1="0.930176"
                    x2="25.4668"
                    y2="50.0036"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="white" stop-opacity="0.5" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
              <img src={item.img} alt="" />
            </div>
            <div className="content-inner">
              <h3>{item.title}</h3>
              <p>{item.text}</p>
            </div>
          </ChooseBlockItem>
        ))}
      </ChooseBlockList>
    </ChooseBlockSecond>
  );
};
const listSecurity = [
  {
    img: chooseIcn01,
    title: "About Audits",
    text: "All information and updated news about TON Holding Audits. At TON Holding, we prioritize security for a secure and robust staking platform",
    animation: "flip-left",
    delay: "0",
  },
  {
    img: chooseIcn02,
    title: "Technical aspects",
    text: "we provide here a clear record of how an audit was completed and this is the foundation for company success",
    animation: "flip-right",
    delay: "500",
  },
  {
    img: chooseIcn03,
    title: "Audit Report",
    text: "The latest update about TON Holding's Audit report. Check here for the details and audit status",
    animation: "flip-up",
    delay: "1000",
  },
];
export default Security;

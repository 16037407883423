import {
  BlockProvide,
  BlockProvideDiamon01,
  BlockProvideDiamon02,
  BlockProvideInner,
  BlockProvideList,
  BlockProvideText,
  CardLeft,
  ItemProvide,
  OrbitContainer,
  WhoBlock,
  WhoLeft,
  WhoList,
  WhoRight,
  WhoRightInner,
  WorksBlock,
  WorksContainer,
  WorksIcn01,
  WorksTitle,
  WorksWrapper,
  WorksWrapperInner,
} from "./styled";
import worksImg01 from "../../../Assets/Home/Works/works_img_01.png";
import worksImg02 from "../../../Assets/Home/Works/works_img_02.png";
import worksImg03 from "../../../Assets/Home/Works/works_img_03.png";
import ButtonCommon from "../../../Components/Button";
import icn01 from "../../../Assets/Home/Keyvisual/icn_01.svg";
import icn02 from "../../../Assets/Home/Works/works_icn_01.svg";
import icn03 from "../../../Assets/Home/Works/provide_icn_04.svg";
import icn04 from "../../../Assets/Home/Works/provide_icn_05.svg";
import Card from "./Card";

import Provide from "./Provide";
const Works = () => {
  return (
    <WorksContainer id="works">
      <WorksWrapper>
        <WorksBlock>
          <WorksWrapperInner>
            {ListData.map((item: any, index: number) => (
              <Card item={item} index={index} />
            ))}
          </WorksWrapperInner>
        </WorksBlock>
        <WhoBlock>
          <WhoLeft>
            <WorksTitle data-aos="fade-right">Who We Are?</WorksTitle>
            <p data-aos="fade-up">
              TON Holding is a liquid staking protocol built on the TON
              blockchain. At TON Holding, you can stake native TON to earn
              real-time rewards
            </p>
            <ButtonCommon animation="fade-up" text="Go To App" image={icn01} />
          </WhoLeft>
          <WhoRight>
            <WhoRightInner>
              <CardLeft data-aos="fade-up">
                <p>100,370 TON</p>
                {/* <WorksIcn01>
                  <img src={icn02} alt="" />
                </WorksIcn01> */}
                <span>Total staked</span>
              </CardLeft>
              <WhoList>
                {ListWho.map((item: any, index: number) => (
                  <li data-aos="fade-up">
                    <p>{item.number}</p>
                    <span>{item.text}</span>
                  </li>
                ))}
              </WhoList>
            </WhoRightInner>
          </WhoRight>
        </WhoBlock>
        <Provide />
      </WorksWrapper>
    </WorksContainer>
  );
};

const ListData = [
  {
    id: 1,
    img: worksImg01,
    number: "01",
    titleSmall: "What's TON Holding ?",
    text: "TON Holding is a liquid staking protocol built on the TON blockchain. At TON Holding, you can stake native TON to earn real-time rewards as iTON tokens in return",
  },
  {
    id: 2,
    img: worksImg02,
    number: "02",
    titleSmall: "Who are Validators ?",
    text: "Validators are network participants who validate blocks and maximize their TON coin while contributing to the security of the TON blockchain",
  },
  {
    id: 3,
    img: worksImg03,
    number: "03",
    titleSmall: "Who are Nominators ?",
    text: "Nominators are our users who stake and lend their TON coins to validators on TON and receive TON coins earned by the validators",
  },
];

const ListWho = [
  {
    number: "32,782",
    text: "Stakers",
  },
  {
    number: "146%",
    text: "Momentary APY",
  },
];

export default Works;

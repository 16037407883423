import React from "react";
import {
  FooterContainer,
  FooterLeft,
  FooterLogo,
  FooterNavigation,
  FooterRight,
  FooterText,
  FooterWrapper,
  FooterWrapperInner,
  ListNavigation,
  ListSocial,
} from "./styled";
import footerLogo from "../../Assets/Footer/footer_logo.svg";
import { Link } from "react-router-dom";
import social01 from "../../Assets/Footer/footer_social_01.svg";
import social02 from "../../Assets/Footer/footer_social_02.svg";
import social03 from "../../Assets/Footer/footer_social_03.svg";
import social04 from "../../Assets/Footer/footer_social_04.svg";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterWrapperInner>
          <FooterLeft data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <FooterLogo>
              <img src={footerLogo} alt="TON Holding" />
            </FooterLogo>
            <FooterText>
              Stake Smart, Reap Rewards. Decentralized, Secure and Easy
              Investment with TON Holding
            </FooterText>
          </FooterLeft>
          <FooterRight>
            {/* <FooterNavigation data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              <h3>Learn More</h3>
              <ListNavigation>
                {Navigation1.map((item: any, index: number) => (
                  <li key={index}>
                    <Link to={item.href}>{item.text}</Link>
                  </li>
                ))}
              </ListNavigation>
            </FooterNavigation>
            <FooterNavigation data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-delay="400">
              <h3>Product</h3>
              <ListNavigation>
                {Navigation2.map((item: any, index: number) => (
                  <li key={index}>
                    <Link to={item.href}>{item.text}</Link>
                  </li>
                ))}
              </ListNavigation>
            </FooterNavigation> */}
            <FooterNavigation data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-delay="800">
              <h3>Contact Us</h3>
              <ListNavigation>
                {Navigation3.map((item: any, index: number) => (
                  <li key={index}>
                    <Link to={item.href}>{item.text}</Link>
                  </li>
                ))}
              </ListNavigation>
              <ListSocial data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-delay="1000">
                {ListDataSocial.map((item: any, index: number) => (
                  <li key={index}>
                    <Link to={item.href} target="_blank">
                      <img src={item.img} alt="Social" />
                    </Link>
                  </li>
                ))}
              </ListSocial>
            </FooterNavigation>
          </FooterRight>
        </FooterWrapperInner>
      </FooterWrapper>
    </FooterContainer>
  );
};

const Navigation1 = [
  {
    href: "#",
    text: "Docs",
  },
  {
    href: "#",
    text: "Documentation",
  },
  {
    href: "#",
    text: "Terms of use",
  },
  {
    href: "#",
    text: "Privacy policy",
  },
];
const Navigation2 = [
  {
    href: "#",
    text: "About us",
  },
  {
    href: "#",
    text: "Home",
  },
  {
    href: "#",
    text: "How it works",
  },
  {
    href: "#",
    text: "Securities",
  },
  {
    href: "#",
    text: "Roadmap",
  },
];
const Navigation3 = [
  {
    href: "mailto:contact@tonholding.com",
    text: "contact@tonholding.com",
  },
];
const ListDataSocial = [
  {
    href: "https://t.me/tonholding_chat",
    img: social01,
  },
  {
    href: "https://t.me/tonholding_channel",
    img: social01,
  },
  // {
  //   href: "https://x.com/TON_Holding",
  //   img: social02,
  // },
  // {
  //   href: "#",
  //   img: social03,
  // },
  // {
  //   href: "#",
  //   img: social04,
  // },
];
export default Footer;

import React, { useContext, useState } from "react";
import {
  ButtonMenu,
  HeaderContainer,
  HeaderContentRight,
  HeaderLogo,
  HeaderWrapper,
  ItemNavigation,
  ListNavigation,
} from "./styled";
import headerLogo from "../../Assets/Header/header_logo.svg";
import headerLogoSp from "../../Assets/Header/header_logo_sp.png";
import icnArrow from "../../Assets/Icons/icn_arrow_white.svg";
import { Link, NavLink } from "react-router-dom";
import ButtonCommon from "../Button";
import { ContextProviderWrapper } from "../Context";

const Header = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [showMenu, setShowMenu] = useState(false);

  //handle show menu
  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };
  const handleCloseMenu = () => {
    setShowMenu(false);
  };
  return (
    <HeaderContainer>
      <HeaderWrapper>
        <HeaderLogo data-aos="fade-right">
          <a href="#">
            <img src={isMobile ? headerLogoSp : headerLogo} alt="TON Holding" />
          </a>
        </HeaderLogo>
        <HeaderContentRight>
          <ListNavigation className={showMenu ? "is-show" : ""}>
            {listNavigation.map((item: any, index: number) => (
              <ItemNavigation key={index}>
                {isMobile ? (
                  <a onClick={handleCloseMenu} href={item.href} target={item.target ? '_blank': ''}>
                    {item.link}
                  </a>
                ) : (
                  <a href={item.href} target={item.target ? '_blank': ''}>{item.link}</a>
                )}
              </ItemNavigation>
            ))}
          </ListNavigation>
          <ButtonCommon
            text="Go To App"
            image={icnArrow}
            animation="fade-left"
            href={"https://app.tonholding.io/"}
            newTabs={true}
          />
        </HeaderContentRight>
        {isMobile && (
          <ButtonMenu
            onClick={handleShowMenu}
            className={showMenu ? "is-active" : ""}
          >
            <span></span>
            <span></span>
            <span></span>
          </ButtonMenu>
        )}
      </HeaderWrapper>
    </HeaderContainer>
  );
};

const listNavigation = [
  {
    link: "How it works",
    href: "#works",
    isMobile: false,
    target: false
  },
  {
    link: "Audits",
    href: "#audits",
    isMobile: false,
    target: false
  },
  {
    link: "Road Map",
    href: "#map",
    isMobile: false,
    target: false
  },
  {
    link: "Why Choose us",
    href: "#why",
    isMobile: false,
    target: false
  },
  {
    link: "Docs",
    href: "https://docs.tonholding.io/",
    isMobile: false,
    target: true
  },
];

export default Header;

import Keyvisual from "./Keyvisual"
import Works from "./Works"
import Choose from "./Choose";
import Roadmap from "./Roadmap";
import 'hover.css/css/hover-min.css';

const HomePage = () => {
  return (
    <>
      <Keyvisual />
      <Works />
      <Choose />
      <Roadmap />
    </>
  )
}

export default HomePage
import styled from "styled-components";
import worksImg02 from "../../../Assets/Home/Works/words_img_02.png";
import line01 from "../../../Assets/Home/Works/provide_line_01.png";
import line02 from "../../../Assets/Home/Works/provide_line_02.png";
import line03 from "../../../Assets/Home/Works/provide_line_03.png";
import workDecor01 from "../../../Assets/Home/Works/work_decor_01.png";
import workDecor02 from "../../../Assets/Home/Works/work_decor_02.png";
import workDecor03 from "../../../Assets/Home/Works/work_decor_03.png";
import { motion } from "framer-motion";

export const WorksContainer = styled.div`
  position: relative;
  /* height: 125vh; */
  z-index: 5;
  background: linear-gradient(
    180deg,
    #050506 0%,
    #1b1c20 20%,
    #1b1c20 60%,
    #010101 100%
  );
  padding-bottom: 80px;
  @media screen and (max-width: 767px) {
    overflow: hidden;
    padding: 100px 15px 0;
  }
`;
export const WorksWrapper = styled.div`
  max-width: 73.178vw;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  padding-top: 1px;
  @media screen and (max-width: 767px) {
    max-width: unset;
    padding: 1px 0;
    width: unset;
  }
`;
export const WorksBlock = styled.div`
  margin-bottom: 50px;
  /* height: 300vh; */
`;
export const WorksLeft = styled.div`
  max-width: 30.834vw;
  width: 100%;
  height: 30.834vw;
  left: 0;
  top: -10%;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    position: absolute;
    top: 0;
    left: 0;
  }
  @media screen and (max-width: 767px) {
    margin: 0 auto;
    max-width: unset;
    width: auto;
    height: 190px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
export const WorksWrapperInner = styled.div`
  position: sticky;
  top: 0;
  .item {
    width: 100%;
    height: 100vh;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:nth-child(1) {
      .item-inner {
        border-radius: 16px;
        background: #0a0b0c;
        &:after {
          background-image: url(${workDecor01});
        }
      }
    }
    &:nth-child(2) {
      .item-inner {
        border-radius: 16px;
        background: #2d2f35;
        &:after {
          background-image: url(${workDecor02});
        }
      }
      /* ${WorksLeft} {
        img {
          width: 26.8vw;
        }
      } */
      .number {
        color: #5a5f6b;
      }
      .title-small {
        color: #cce7ff;
      }
    }
    &:nth-child(3) {
      .item-inner {
        border-radius: 16px;
        background: #0085ff;
        &:after {
          background-image: url(${workDecor03});
        }
      }
      .number {
        color: #55aeff;
      }
      .title-small {
        color: #f8f9fb;
      }
    }
  }
  .item-inner {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      background: top right / contain no-repeat;
      width: 19.445vw;
      height: 19.445vw;
      pointer-events: none;
    }
    img {
      width: 27.631vw;
      mix-blend-mode: lighten;
    }
  }
  .number {
    color: var(--Gray-90, #2d2f35);
    font-size: 6.66667vw;
    font-weight: 600;
    margin-bottom: -10px;
    display: block;
  }
  .title-small {
    color: var(--Blue-Base, #0085ff);
    font-family: "Anek Odia";
    font-size: 3.055555vw;
    font-weight: 500;
    display: block;
  }
  p {
    color: var(--Light-Gray-Base, #dce3eb);
    font-family: "Anek Odia";
    font-size: 1.388888vw;
    font-weight: 400;
  }
  @media screen and (max-width: 767px) {
    display: block;
    .item {
      position: static;
      height: unset;
      transform: unset !important;
      &:nth-child(1) {
        .item-inner {
          background: #2d2f35;
        }
        .number {
          color: #5a5f6b;
        }
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    .number {
      font-size: 60px;
    }
    .title-small {
      font-size: 32px;
    }
    .item-inner {
      padding: 16px;
      width: unset;
      display: block;
      &:after {
        width: 150px;
        height: 150px;
      }
    }
    p {
      font-size: 16px;
    }
    ${WorksLeft} {
      img {
        width: auto !important;
      }
    }
  }
`;

export const WorksRight = styled.div`
  max-width: 30.3vw;
  width: 100%;
  position: relative;
  @media screen and (max-width: 767px) {
    max-width: unset;
  }
`;
export const WorksTitle = styled(motion.h2)`
  font-size: 2.777777vw;
  font-weight: 500;
  background: linear-gradient(90deg, #fff 38.73%, #2d2f35 129.1%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  @media screen and (max-width: 767px) {
    font-size: 36px;
  }
`;
export const WorksList = styled.ul`
  color: #fff;
  /* overflow: hidden; */
  /* height: 425px; */
`;
export const ItemList = styled.li`
  border-top: 1px solid #5a5f6b;
  padding-top: 32px;
  min-height: 350px;
  transition: transform 0.26s ease;
  &:last-child {
    border-bottom: 1px solid #5a5f6b;
    padding-bottom: 32px;
  }
  &:not(:last-child) {
    margin-bottom: 32px;
  }
  .number {
    color: var(--Gray-80, #444750);
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
    display: inline-block;
    transition: all 0.26s ease;
  }
  h3 {
    margin-bottom: 10px;
    transition: all 0.26s ease;
  }
  .title-small {
    color: var(--Blue-Base, #0085ff);
    font-size: 32px;
    font-weight: 300;
    transition: all 0.26s ease;
  }
  .title-big {
    color: var(--Blue-Base, #0085ff);
    font-size: 32px;
    font-weight: 500;
    display: block;
    margin-top: -10px;
    transition: all 0.26s ease;
  }
  p {
    color: var(--Light-Gray-Base, #dce3eb);
    font-size: 1.11111vw;
    font-weight: 400;
    transition: all 0.26s ease;
    margin-bottom: 1vw;
  }
  @media screen and (max-width: 767px) {
    min-height: unset;
    p {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
`;

// Who Block
export const WhoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 200px;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
export const WhoLeft = styled.div`
  max-width: 21vw;
  width: 100%;
  p {
    color: var(--Light-Gray-Base, #dce3eb);
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 767px) {
    max-width: unset;
    margin-bottom: 20px;
  }
`;
export const WhoRight = styled.div``;
export const WhoRightInner = styled.div`
  display: flex;
  gap: 16px;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
export const CardLeft = styled.div`
  position: relative;
  width: 13.2vw;
  min-height: 30.695vw;
  background: url(${worksImg02}) center / cover no-repeat;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  p {
    color: var(--Blue-10, #cce7ff);
    font-size: 2.777777vw;
    font-weight: 400;
  }
  span {
    margin-top: auto;
    color: var(--Light-Gray-Base, #dce3eb);
    font-size: 1.388888vw;
    font-weight: 400;
  }
  @media screen and (max-width: 767px) {
    width: unset;
    margin-bottom: 15px;
    min-height: 318px;
    p {
      font-size: 40px;
    }
    span {
      font-size: 20px;
    }
  }
`;
export const WorksIcn01 = styled.div`
  position: absolute;
  left: -46px;
  bottom: 120px;
`;
export const WhoList = styled.ul`
  min-height: 30.695vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  li {
    width: 13.2vw;
    height: 15.3475vw;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    p {
      color: var(--Blue-Base, #0085ff);
      font-size: 40px;
      font-weight: 400;
    }
    span {
      color: var(--Gray-Base, #878ea0);
      font-size: 20px;
      font-weight: 400;
      margin-top: auto;
    }
    &:nth-child(1) {
      background: var(--Light-Gray-50, #e2e8ee);
    }
    &:nth-child(2) {
      background: var(--Blue-Base, #0085ff);
      p {
        color: #fff;
      }
      span {
        color: #dce3eb;
      }
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  @media screen and (max-width: 767px) {
    li {
      width: unset;
      height: 140px;
    }
  }
`;

//Block Provide
export const BlockProvide = styled.div``;
export const BlockProvideInner = styled.div`
  text-align: center;
  position: relative;
`;
export const OrbitContainer = styled.div`
  position: relative;
  .orbit-line {
    position: absolute;
    top: 0;
    left: 50%;
    background: center / contain no-repeat;
    mix-blend-mode: plus-lighter;
    pointer-events: none;
    span {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #fff;
      display: block;
      position: absolute;
    }
    &:nth-child(1) {
      top: -60px;
      width: 178px;
      height: 178px;
      background-image: url(${line01});
      animation: 50s linear 0s infinite normal none running spin;
      margin-left: -89px;
      span {
        top: 3px;
        left: 42px;
        animation: 50s linear 0s infinite normal none running spin;
      }
    }
    &:nth-child(2) {
      top: -100px;
      width: 278px;
      height: 278px;
      background-image: url(${line02});
      animation: 50s linear 0s infinite normal none running spinRevert;
      margin-left: -139px;
      span {
        top: 20px;
        left: 49px;
      }
    }
    &:nth-child(3) {
      top: -140px;
      width: 378px;
      height: 378px;
      background-image: url(${line03});
      animation: 50s linear 0s infinite normal none running spin;
      margin-left: -189px;
      span {
        top: 20px;
        left: 95px;
        width: 10px;
        height: 10px;
      }
    }
  }
  &:before {
    position: absolute;
    content: "";
    top: -180px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 552px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 87, 255, 0.5) 0%,
      rgba(0, 87, 255, 0) 100%
    );
    filter: blur(150px);
    width: 552px;
    height: 552px;
    pointer-events: none;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  @keyframes spinRevert {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media screen and (max-width: 767px) {
    &:before {
      max-width: 350px;
      width: 100%;
      height: 350px;
    }
  }
`;
export const BlockProvideDiamon01 = styled.div`
  position: absolute;
  left: calc(50% - 340px);
  top: -90px;
  @media screen and (max-width: 767px) {
    left: 0;
    top: -160px;
  }
`;
export const BlockProvideDiamon02 = styled.div`
  position: absolute;
  right: calc(50% - 340px);
  top: 20px;
  @media screen and (max-width: 767px) {
    right: 0;
    top: -100px;
  }
`;
export const BlockProvideText = styled.p`
  color: var(--Light-Gray-Base, #dce3eb);
  font-size: 1.11111vw;
  font-weight: 300;
  width: 20vw;
  margin: 0 auto 2vw;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    width: 288px;
    margin-bottom: 30px;
  }
`;
export const BlockProvideList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
export const ItemProvide = styled.li`
  width: 19.3vw;
  min-height: 17vw;
  flex-shrink: 0;
  border-radius: 13.965px;
  background: var(--Light-Gray-Base, #dce3eb);
  backdrop-filter: blur(13.092254638671875px);
  padding: 28px;
  transition: background 0.26s ease;
  img {
    width: 3.403vw;
  }
  .title-small,
  .title-big,
  p {
    transition: color 0.26s ease;
  }
  .title-small {
    color: var(--Gray-90, #2d2f35);
    font-size: 1.666666vw;
    font-weight: 300;
  }
  .title-big {
    color: var(--Gray-90, #2d2f35);
    font-size: 1.666666vw;
    font-weight: 500;
    display: block;
    margin-top: -12px;
  }
  p {
    color: var(--Gray-60, #717685);
    font-size: 0.97223vw;
    font-weight: 400;
  }
  &:hover {
    background: var(--Blue-Base, #0085ff);
    .title-small,
    .title-big {
      color: #f8f9fb;
    }
    p {
      color: #dce3eb;
    }
  }
  @media screen and (max-width: 767px) {
    width: unset;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .title-small,
    .title-big {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }
    img {
      width: 49px;
    }
  }
`;
